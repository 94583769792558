import React from "react"

import LinkGatsby from "../LinkGatsby"

export default function Nav() {
  return (
    <LinkGatsby to="/sobre" activeClassName="active" partiallyActive>
      Sobre
    </LinkGatsby>
  )
}
