import styled from "@emotion/styled"
import { Link } from "gatsby"
import tw from "twin.macro"

const LinkGatsby = styled(Link)`
  ${tw`text-highlight underline font-sans mt-1 lg:mt-0 outline-none focus:shadow-primary-opacity-60`}

  &.active {
    ${tw`text-paragraph no-underline`}
  }

  &:hover {
    color: #0342b0;
  }
`

export default LinkGatsby
