import styled from "@emotion/styled"
import tw from "twin.macro"

const WrapperHeader = styled.div`
  ${tw`relative lg:static flex items-start lg:items-center justify-between z-10`}

  min-height: 8rem;

  @media (min-width: 1024px) {
    min-height: initial;
  }
`

export default WrapperHeader
