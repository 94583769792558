import styled from "@emotion/styled"
import tw from "twin.macro"

const ButtonPrimary = styled.button`
  ${tw`text-white py-2 px-3 mt-4 mb-3 text-lg rounded-md focus:outline-none focus:shadow-primary-opacity-60`}

  background-color: #156ba3;

  &:not(:disabled):hover {
    background-color: #1561a3;
  }

  &:disabled {
    ${tw`opacity-50 cursor-not-allowed`}
  }
`

export default ButtonPrimary
