import React from "react"
import { Global, css } from "@emotion/core"
import styled from "@emotion/styled"
import tw from "twin.macro"
import "typeface-roboto"

const Wrapper = styled.div`
  ${tw`h-full px-8 lg:px-12 py-8 max-w-screen-xl m-auto bg-white flex flex-col justify-start text-primary`}

  min-height: 830px
`

export default function Layout({ children, ...rest }) {
  return (
    <>
      <Global
        styles={css`
          html,
          body,
          body > div,
          #gatsby-focus-wrapper {
            height: 100%;
          }
        `}
      />
      <Wrapper {...rest}>{children}</Wrapper>
    </>
  )
}
