import React from "react"
import styled from "@emotion/styled"
import tw from "twin.macro"
import { Link } from "gatsby"

const LogoText = styled.h1`
  ${tw`text-primary font-bold font-sans text-2xl`}

  & > a {
    ${tw`outline-none focus:shadow-primary-opacity-60`}
  }
`

export default function LogoLocalDeProva() {
  return (
    <LogoText>
      <Link to="/">Local de Prova</Link>
    </LogoText>
  )
}
